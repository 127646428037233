<template>
    <GenericPage
        :header-props="{
            title: $t('cp__settings__review_api__title'),
            subtitle: $t('cp__settings__review_api__subtitle'),
            disableBackButton: true,
        }"
        :loading="isLoading"
    >
        <SettingsForm v-if="!isLoading" class="settings_form">
            <SettingsTextBlock
                :title="$t('cp__settings__review_api__available_connections__title')"
                class="settings_block"
            >
                <p class="text">
                    {{ $t("cp__settings__review_api__available_connections__message") }}
                    <a
                        class="link"
                        target="_blank"
                        href="https://intercom.help/feedbackcompany/nl/collections/2424875-api-review-research-portal"
                        >{{ $t("cp__settings__review_api__available_connections__linktext") }}</a
                    >.
                </p>
            </SettingsTextBlock>

            <SettingsBlock class="settings_block" title="Account">
                <SettingsSection :label="$t('cp__settings__review_api__account__connector_code')">
                    <SettingsHelpText>{{
                        $t("cp__settings__review_api__account__connector_code__label")
                    }}</SettingsHelpText>
                    <div>
                        <FieldToClipboard
                            :value="settings.account.connectorCode.toString()"
                            @onCopied="triggerCopyToast"
                        />
                    </div>
                </SettingsSection>
                <SettingsSection
                    :label="$t('cp__settings__review_api__account__feedback_company_id')"
                >
                    <SettingsHelpText>{{
                        $t("cp__settings__review_api__account__feedback_company_id__label")
                    }}</SettingsHelpText>
                    <div>
                        <FieldToClipboard
                            :value="settings.account.feedbackCompanyId.toString()"
                            @onCopied="triggerCopyToast"
                        />
                    </div>
                </SettingsSection>
            </SettingsBlock>

            <SettingsBlock
                v-if="settings.credentials"
                class="settings_block"
                :title="$t('cp__settings__review_api__credentials')"
            >
                <SettingsSection label="Client ID">
                    <SettingsHelpText>{{
                        $t("cp__settings__review_api__credentials__client_id__label")
                    }}</SettingsHelpText>
                    <div>
                        <FieldToClipboard
                            :value="settings.credentials.clientId"
                            @onCopied="triggerCopyToast"
                        />
                    </div>
                </SettingsSection>
                <SettingsSection label="Client Secret">
                    <SettingsHelpText>{{
                        $t("cp__settings__review_api__credentials__client_secret__label")
                    }}</SettingsHelpText>
                    <div>
                        <FieldToClipboard
                            :value="settings.credentials.clientSecret"
                            @onCopied="triggerCopyToast"
                        />
                    </div>
                </SettingsSection>
            </SettingsBlock>
            <SettingsTextBlock
                v-else
                :title="$t('cp__settings__review_api__credentials')"
                class="settings_block"
            >
                <p class="text text--grey">
                    {{ $t("cp__settings__review_api__credentials__missing_1") }}
                    <a href="mailto:helpdesk@feedbackcompany.com" class="link"
                        >helpdesk@feedbackcompany.com</a
                    >
                    {{ $t("cp__settings__review_api__credentials__missing_2") }}
                </p>
            </SettingsTextBlock>
        </SettingsForm>
    </GenericPage>
</template>

<script>
import FieldToClipboard from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/FieldToClipboard.vue";
import { mapGetters } from "vuex";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsHelpText from "@/components/Settings/Molecules/SettingsHelpText.vue";
import SettingsTextBlock from "@/components/Settings/Molecules/SettingsTextBlock.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import getReviewApiSettings from "@/graphql/settings/getReviewApiSettings.gql";

export default {
    name: "ReviewApiSettings",
    components: {
        GenericPage,
        SettingsBlock,
        SettingsSection,
        SettingsHelpText,
        SettingsForm,
        SettingsTextBlock,
        FieldToClipboard,
    },
    data() {
        return {
            isLoading: true,
            settings: null,
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
        }),
    },
    methods: {
        async triggerCopyToast(value) {
            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message", { value }),
            });
        },
        async getSettings() {
            const { data } = await this.$apollo.query({
                query: getReviewApiSettings,
                variables: { customerName: this.activeAccount.slug },
            });
            this.settings = data.settings.apiSettings;
            this.isLoading = false;
        },
    },
    async mounted() {
        await this.getSettings();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.settings_form {
    width: 100%;
    max-width: 950px;
    margin-bottom: 40px;
}
.settings_block {
    width: 100%;
    margin-top: 36px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }
}

.text {
    max-width: 770px;
    margin: 0;
    letter-spacing: 0;

    .link {
        @extend %anchor_style;
    }

    &.text--grey {
        color: $grey_manatee;
    }
}
</style>
